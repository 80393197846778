import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {PASSWORD} from '../../util/auth_methods';
import routes from '../../util/routes';

// noinspection FunctionNamingConventionJS
function ConditionalChangePasswordRoute({ component: Component, ...rest }) {

  const authMethod = useSelector(state => state.systemInfo.authMethod);

  return (<Route
      {...rest}
      render={(props) =>
          authMethod === PASSWORD ?
              <Component {...props} /> :
              <Redirect to={routes.users.path} />
      }
  />);
}

ConditionalChangePasswordRoute.propTypes = {};

ConditionalChangePasswordRoute.defaultProps = {};

export default ConditionalChangePasswordRoute;
