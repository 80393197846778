import React from 'react';
import { Alert, useTheme } from '@mui/material';
import { StyledLogsList, StyledLogsWrapper } from '../common/styled';
import CircularProgressButton from '../common/CircularProgressButton';
import PropTypes from 'prop-types';

// noinspection FunctionNamingConventionJS
function LogComponent(props) {

  const theme = useTheme();

  const {isLoading: loadingLogs, logs, error, loadMore, loadMoreDisabled} = props

  return (
    <>
        <StyledLogsWrapper theme={theme}>
          {loadingLogs && <p style={{width: '100%' }}>Loading logs...</p>}
          <StyledLogsList theme={theme}>
            {(!loadingLogs && logs.length === 0 && error.status === 0) &&
              <li key={-1} style={{width: '100%'}}>
                No logs available for the selected date
              </li>}
            {logs.map((log, idx) => <li key={idx}><div>{log?.message}</div></li>)}
            {error.status !== 0 && <Alert severity="error">{error.message}</Alert>}
          </StyledLogsList>
        </StyledLogsWrapper>
        {props.logs.length !== 0 &&
          <CircularProgressButton onClick={loadMore} label='Load More' size={'small'} variant={'text'}
                                  buttonTextTransform={'none'} inProgress={loadingLogs}
                                  inProgressIconSize={20}
                                  disabled={loadMoreDisabled || loadingLogs}/>
        }
    </>);
}

LogComponent.propTypes = {
  logs: PropTypes.array,
  isLoading: PropTypes.bool,
  error: PropTypes.object,
  loadMore: PropTypes.func,
  loadMoreDisabled: PropTypes.bool
};

LogComponent.defaultProps = {};

export default LogComponent;
