import React, {useState} from 'react';
import _ from 'lodash';
import toast from 'toasted-notes';
import {useHistory} from 'react-router-dom';
import {Card, CardContent, useTheme} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CustomAlert from '../common/CustomAlert';
import CustomCardHeader from '../common/CustomCardHeader';
import {StyledBreadcrumbIcon, StyledBreadcrumbLink, StyledBreadcrumbs} from '../common/styled';
import UserCreateForm from './UserCreateForm';
import routes from '../../util/routes';
import {isEmpty} from '../../util/helpers';
import httpStatus from '../../util/http_status';
import {convertErrorsToObject} from '../../util/errorHandler';
import useFolder from '../../api/useFolder';
import useCloudConnection from "../../api/useCloudConnection";

// noinspection AnonymousFunctionJS, MagicNumberJS
const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
    }
}));

const initUser = {
    username: '',
    notes: '',
    homeDirectory: '1', // default or custom home dir choice
    homeFolderId: null, // id of the home dir
    permission: 'READ_WRITE',   // permission for the home dir ('READ_WRITE' by default)
    publicKeys: [],
    password: '',
    uid: '',
    gid: '',
    allowedIps: [],
    ipAllowListEnabled: false,
};

// noinspection FunctionNamingConventionJS
function UserCreateComponent(props) {
    const classes = useStyles();
    const history = useHistory();
    const theme = useTheme();

    const {saveUser, searchFolder} = {...props};

    const [isBlocking, setIsBlocking] = useState(false);
    const [user, setUser] = useState(initUser);
    const [errors, setErrors] = useState({});
    const {folder: homeFolder} = useFolder({params: {folderId: user.homeFolderId}});

    const {cloudConnection: defaultConnection} = useCloudConnection({params: {cloudConnectionId: 1}});

    const handleTextChange = function handleTextChangeOnUserForm(event) {
        setIsBlocking(true);
        setUser({...user, [event.target.name]: event.target.value});
    };

    const handleCheckboxChange = function handleCheckboxChangeOnUserForm({name, value}) {
        setIsBlocking(true);
        setUser({...user, [name]: value});
        // If we want to enable blocking ip addresses, we need to provide at least one ip address
        if (name === 'ipAllowListEnabled' && (value === true && user.allowedIps.length === 0)) {
            setErrors({
                ...errors,
                ipAllowListEnabled: 'At least one IP Allowlist entry must be entered when enabling the IP Allowlist.'
            });
        } else if (name === 'ipAllowListEnabled' && (value === false || user.allowedIps.length > 0)) {
            setErrors({...errors, ipAllowListEnabled: ''});
        }
    };

    const setHomeFolderAndPermission = function setHomeFolderAndPermission({homeFolderId, permission}) {
        setUser({...user, homeFolderId, permission});
    };

    const addPublicKey = function addPublicKeyToSSHKeysTableOnUserForm(key) {
        setIsBlocking(true);
        let tempPublicKeys = user.publicKeys;
        tempPublicKeys = tempPublicKeys.concat(key);
        setUser({...user, publicKeys: tempPublicKeys});
    };

    const updatePublicKey = async function handleUpdatePublicKeyFromEditModal(index, key) {
        let tempPublicKeys = user.publicKeys;
        tempPublicKeys[index].enabled = key.enabled;
        tempPublicKeys[index].name = key.name;
        setUser({...user, publicKeys: tempPublicKeys});
    };

    const removePublicKey = function deletePublicKeyFromSSHKeysTableOnUserForm(index) {
        setIsBlocking(true);
        let tempPublicKeys = user.publicKeys;
        _.remove(tempPublicKeys, function removeKey(publicKey, idx) {
            return idx === index;
        });
        setUser({...user, publicKeys: tempPublicKeys});
    };

    const addIpAddress = function addIpAddressToIpAddressAllowlistTableOnUserForm(address) {
        setIsBlocking(true);
        let tempIpAddresses = user.allowedIps;
        tempIpAddresses = tempIpAddresses.concat(address);
        setUser({...user, allowedIps: tempIpAddresses});
        setErrors({...errors, ipAllowListEnabled: ''})
    };

    const removeIpAddress = function deleteIpAddressFromIpAddressAllowlistTableOnUserForm(index) {
        setIsBlocking(true);
        let tempIpAddresses = user.allowedIps;
        _.remove(tempIpAddresses, function removeIp(ipAddress, idx) {
            return idx === index;
        });
        setUser({...user, allowedIps: tempIpAddresses});
        if (user.ipAllowListEnabled && tempIpAddresses.length === 0) {
            setErrors({
                ...errors,
                ipAllowListEnabled: 'At least one IP Allowlist entry must be entered when enabling the IP Allowlist.'
            });
        }
    };

    const handleSubmit = async function handleSaveUser(event) {
        event.preventDefault();
        let tempUser;
        try {
            if (user.homeDirectory === '1') {
                tempUser = {...user, homeFolderId: null, permission: 'READ_WRITE'};
            } else {
                tempUser = user;
            }
            await saveUser(tempUser);
            toast.notify(({onClose}) => <CustomAlert message={'New user added!'} onClose={onClose}/>);
            history.push(routes.users.path);
        } catch (error) {
            if (!isEmpty(error.response) && (error.response.status === httpStatus.conflict)) {
                setErrors({username: 'Duplicate entry.'});
            } else if (!isEmpty(error.response.data) && (error.response.data.errors.some(
                obj => obj.field === 'name'))) {
                let folderRes = await searchFolder(`users/${user.username}`);
                if (folderRes?.data.length === 1) {
                    let folder = folderRes.data[0];
                    tempUser = {...user, homeFolderId: folder.id, permission: 'READ_WRITE'};
                    await saveUser(tempUser);
                    toast.notify(({onClose}) => <CustomAlert
                            onClose={onClose}> New User Added! <br/> <b>Note:</b> The folder `/users/{user.username}` already
                            exists. This folder was selected as the home directory for this user.
                        </CustomAlert>
                    );
                    history.push(routes.users.path);
                } else {
                    let tempErrors = convertErrorsToObject(error.response);
                    setErrors(tempErrors);
                }
            } else {
                let tempErrors = convertErrorsToObject(error.response);
                setErrors(tempErrors);
            }
        }
    };

    const handleCancel = function handleCancel() {
        if (isBlocking) {
            if (window.confirm('Are you sure you would like to cancel? Your changes will not be saved.')) {
                setIsBlocking(false);
                history.push(routes.users.path);
            }
        } else {
            history.push(routes.users.path);
        }
    };

    return (
        <div className={classes.root}>
            <StyledBreadcrumbs aria-label='back to users' theme={theme}>
                <StyledBreadcrumbLink color='inherit' onClick={() => history.push(routes.users.path)}>
                    <StyledBreadcrumbIcon theme={theme}/>
                    Back to users
                </StyledBreadcrumbLink>
            </StyledBreadcrumbs>
            <Card variant='outlined'>
                <CustomCardHeader
                    title={routes.createUser.pageTitle}
                    titleAlign='center'
                />
                <CardContent>
                    <UserCreateForm
                        username={user.username} setUsername={handleTextChange}
                        notes={user.notes} setNotes={handleTextChange}
                        homeFolder={homeFolder} homeFolderPermission={user.permission}
                        homeDirectory={user.homeDirectory} setHomeDirectory={handleTextChange}
                        setHomeFolderAndPermission={setHomeFolderAndPermission}
                        publicKeys={user.publicKeys} addPublicKey={addPublicKey}
                        removePublicKey={removePublicKey} updatePublicKey={updatePublicKey}
                        uid={user.uid} setUid={handleTextChange}
                        gid={user.gid} setGid={handleTextChange}
                        defaultConnection={defaultConnection}
                        password={user.password} setPassword={handleTextChange}
                        allowedIps={user.allowedIps} addIpAddress={addIpAddress} removeIpAddress={removeIpAddress}
                        ipAllowListEnabled={user.ipAllowListEnabled} setIpAllowListEnabled={handleCheckboxChange}
                        handleSubmit={handleSubmit} handleCancel={handleCancel}
                        errors={errors}
                    />
                </CardContent>
            </Card>
        </div>
    );
}

export default UserCreateComponent;
