import { IconButton, Table, TableCell, TableRow, Typography, useTheme } from "@mui/material";
import React from "react";
import { faCopy as CopyIcon } from "@fortawesome/free-regular-svg-icons";
import toast from "toasted-notes";
import CustomAlert from "../../common/CustomAlert";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export function IdentityProviderConfigInfo(props) {
  const theme = useTheme();

  const StyledIcon = styled(FontAwesomeIcon)`
    height: 18px;
    width: 25px;
`;

  const regPrefix = BASE_URL.startsWith("http") ?  "" : window.location.origin;
  const values = [
    {
      label: 'Authorized JavaScript Origin',
      value: window.location.origin
    },
    {
      label: 'Authorized Redirect URI / Callback URL',
      value: (props.registrationId && `${regPrefix}${BASE_URL}/login/oauth2/code/${props.registrationId}`) || <i>Provide registration id to view Redirect URI</i>
    },
    {
      label: 'Front-channel Logout URI / Sign out URL',
      value: window.location.origin
    },
  ]

  const copyValue = (label, value) => {
    navigator.clipboard.writeText(value).then(() => {
      toast.notify(({onClose}) => <CustomAlert type='success' message={`${label} copied!`} onClose={onClose}/>);
    });
  };

  return <>
    <Typography variant={"h5"}>Fields for Identity Provider</Typography>
    <Typography variant={"caption"}>Provide these fields when appropriate during configuration of OIDC on the identity
      provider.</Typography>

    <Table>
      {values.map(row =>
        <TableRow>
          <TableCell>
            <Typography variant={"body1"}>
              {row.label}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant={"body2"}>
              {row.value} <IconButton size={"small"} style={{height: '25px', width: '25px'}} onClick={function handleCopy(event) {
              event.stopPropagation()
              copyValue(row.label, row.value);
            }}>
              <StyledIcon icon={CopyIcon} color={theme.palette.primary.main} size="sm"/>
            </IconButton>
            </Typography>
          </TableCell>
        </TableRow>
      )}

    </Table>

  </>;
}