
import React, {useEffect, useState} from 'react';
import axios from "axios";
import api_routes from "../util/api_routes";

function useSftpConfig(){
    const [isLoading, setIsLoading] = useState(true);
    const [bannerText, setBannerText] = useState(null);

    useEffect(function loadSftpConfig(){
        const fetchData = async function fetchBannerText() {
            try {
                const result = await axios.get(api_routes.bannerText.endpoint);
                setBannerText(result.data.bannerText);
                setIsLoading(false);
            } catch (error) {
                console.error(error);
            }
        };

        setIsLoading(true);
        // noinspection JSIgnoredPromiseFromCall
        fetchData();
        }, [] );

    return {isLoading, bannerText};
}

export default useSftpConfig;
