import React, {useState} from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import {Grid} from '@mui/material';
import styled from 'styled-components';
import CircularProgressButton from '../common/CircularProgressButton';
import {convertErrorsToObject} from '../../util/errorHandler';
import httpStatus from '../../util/http_status';
import api_routes from '../../util/api_routes';
import PasswordField from "../common/PasswordField";

const StyledLogo = styled.img`
  width: 100%;
`;

const StyledFormLogo = styled.div`
  width: 75%;
`;

const StyledFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 360px;
`;

const StyledFormContent = styled.div`
  display: flex;
  width: 100%;
  color: rgb(148, 146, 146);
  margin: 24px 0px 8px;
  &:before {
    content: "";
    flex: 1 1 0%;
    border-bottom: 1px solid rgb(216, 216, 216);
    margin: 0px 22px 8px 0px;
   }
  &:after {
    content: "";
    flex: 1 1 0%;
    border-bottom: 1px solid rgb(216, 216, 216);
    margin: 0px 0px 8px 22px;
  }
`;

const initChangePasswordForm = {
    newPassword: '',
    confirmNewPassword: '',
}

// noinspection FunctionNamingConventionJS
function UpdatePassword(props) {

    const {currentPassword} = props;
    const [isLoading, setIsLoading] = useState(false);
    const [passwordErrors, setPasswordErrors] = useState(initChangePasswordForm);
    const [changePasswordForm, setChangePasswordForm] = useState(initChangePasswordForm);

    const handlePasswordUpdate = async function handlePasswordUpdateOnThePasswordUpdateForm(event) {
        event.preventDefault();
        setIsLoading(true);
        if (changePasswordForm.newPassword === changePasswordForm.confirmNewPassword) {
            try {
                await axios.patch(`${api_routes.adminUser.endpoint}/password`, {
                    currentPassword,
                    newPassword: changePasswordForm.newPassword
                });
                props.onSuccess(props.username, changePasswordForm.newPassword);
            } catch (error) {
                if (error.response.status === httpStatus.badRequest) {
                    let tempErrors = convertErrorsToObject(error.response);
                    setPasswordErrors({confirmNewPassword: '', newPassword: tempErrors.newPassword});
                    setIsLoading(false);
                }
            }
        } else {
            setPasswordErrors({newPassword: '', confirmNewPassword: 'New passwords don\'t match'});
            setIsLoading(false);
        }
    };

    const handleChange = function updateChangePasswordFormFields(event) {
        setChangePasswordForm(
            {...changePasswordForm, [event.target.name]: event.target.value})
    };

    return (
        <Grid container justifyContent='center' alignItems='center' className='login-form'>
            <StyledFormWrapper className='form-wrapper'>
                <StyledFormLogo className='form-logo'>
                    <StyledLogo src='/images/sftpgateway-logo-final.png'
                                alt='SFTPgateway logo'/>
                    <StyledLogo src='/images/sftpgateway-logo-final-text.png'
                                alt='SFTPgateway logo text'/>
                </StyledFormLogo>
                <StyledFormContent className='form-content'>
                    Update Password
                </StyledFormContent>
                <div>
                    <form onSubmit={handlePasswordUpdate}>
                        <PasswordField width='100' required name='newPassword'
                                       placeholder='Enter complex password' label='New Password'
                                       tooltip="At least one capital letter, one lower case letter, one number,
                                          and one special character"
                                       password={changePasswordForm.newPassword} handleChange={handleChange}
                                       errorMessage={passwordErrors.newPassword}/>
                        <PasswordField errorMessage={passwordErrors.confirmNewPassword}
                                       width='100' required placeholder='Re-enter complex password'
                                       name='confirmNewPassword' password={changePasswordForm.confirmNewPassword}
                                       label='Confirm New Password' handleChange={handleChange}/>
                        <CircularProgressButton label='Update Password' variant='contained' mt={2}
                                                type='submit' inProgress={isLoading}/>
                    </form>
                </div>
            </StyledFormWrapper>
        </Grid>
    );
}

UpdatePassword.propTypes = {
    currentPassword: PropTypes.string.isRequired,
    username: PropTypes.string,
    onSuccess: PropTypes.func,
};

UpdatePassword.defaultProps = {};

export default UpdatePassword;
