import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import PageSection from '../../common/PageSection';
import InlineEditTextField from '../../common/InlineEditTextField';
import FormControlRadioGroup from '../../common/FormControlRadioGroup';
import SuccessMessageComponent from '../../common/SuccessMessageComponent';
import TestConnectionResultTable from './TestConnectionResultTable';
import { isEmpty } from '../../../util/helpers';
import HelpSystemContext from '../../../context/HelpSystemContext';

// noinspection FunctionNamingConventionJS
function AwsCloudConnectionEditForm(props) {

  const [errors, setErrors] = useState(props.errors);
  const [localInstanceCredentials, setLocalInstanceCredentials] = useState({accessKeyId: '', accessSecret: ''});
  const [showSavedMessage, setShowSavedMessage] = useState(false);
  const [showEncryptionTypeSavedMessage, setShowEncryptionTypeSavedMessage] = useState(false);

  useEffect(() => {
    setErrors(props.errors);
  }, [props.errors]);

  const updateEncryptionType = async function updateEncryptionType(event) {
    props.setEncryptionType(event);
    if (event.target.value === 'SSE_KMS' && isEmpty(props.encryptionId)) {
      setErrors({...errors, encryptionId: 'Please provide encryption ID.'});
    } else {
      props.handleSubmit(event, {encryptionType: event.target.value});
      setErrors({...errors, encryptionId: ''});
      setShowEncryptionTypeSavedMessage(true);
    }
  };

  const updateEncryptionId = async function updateEncryptionId(event) {
    if (isEmpty(event.target.value)) {
      const tempError = {...errors, encryptionId: 'Please provide encryption ID.'};
      throw tempError;
    } else {
      props.handleSubmit(event, {encryptionType: 'SSE_KMS', encryptionId: event.target.value});
    }
  };

  const updateInstanceCredentialsType = async function updateInstanceCredentialsType(event) {
    props.setInstanceCredentials(event);
    await props.handleSubmit(event, {useInstanceCredentials: event.target.value});
    setShowSavedMessage(true);
  };

  const handleInstanceCredentials = async function handleInstanceCredentials(event, data) {
    event.preventDefault();
    setLocalInstanceCredentials({...localInstanceCredentials, ...data});
    await props.handleSubmit(event, {...localInstanceCredentials, ...data});
    await props.handleSubmit(event, {useInstanceCredentials: false});
  };

  //noinspection MagicNumberJS, ConditionalExpressionJS
  return (
      <HelpSystemContext.Consumer>
        {open =>
            <form>
              <Grid container justifyContent='center'>
                <Grid item md={open ? 12 : 8} xs={12}>
                  <InlineEditTextField name='name' label='Connection Name' labelVariant='body1' value={props.name}
                                       save={(event, data) => props.handleSubmit(event, data)}
                                       helperText='Name used to identify this cloud connection.'
                                       placeholder='Enter unique name' required
                                       updateMessage='Saved' errorMessage={errors.name}/>
                </Grid>
                <Grid item md={open ? 12 : 8} xs={12}>
                  <InlineEditTextField name='notes' label='Cloud Connection Notes' labelVariant='body1' value={props.notes}
                                       save={(event, data) => props.handleSubmit(event, data)}
                                       placeholder='Enter notes about the cloud connection (optional)'
                                       multiline showCount inputProps={{maxLength: 1000}}
                                       updateMessage='Saved' errorMessage={errors.notes}/>
                </Grid>
                <Grid item md={open ? 12 : 8} xs={12}>
                  <InlineEditTextField name='basePrefix' label='S3 URL' labelVariant='body1' value={props.basePrefix}
                                       save={(event, data) => props.handleSubmit(event, data)} required autoComplete='off'
                                       placeholder='Enter URL for S3 bucket' helperText="Ex: s3://bucket-name/key-prefix"
                                       errorMessage={errors.basePrefix} updateMessage='Saved' copyField={true}
                  />
                </Grid>
                <Grid item md={open ? 12 : 8} xs={12}>
                  <InlineEditTextField name='region' label='Region' labelVariant='body1' value={props.region}
                                       save={(event, data) => props.handleSubmit(event, data)} autoComplete='off'
                                       placeholder='Enter a bucket region or leave blank for default'
                                       helperText="Ex: us-east-1"
                                       errorMessage={errors.region} updateMessage='Saved'/>
                </Grid>
                <Grid container item md={open ? 12 : 8}>
                  <Grid item xs={12}>
                    <PageSection title='S3 Encryption Type' titleVariant='body1'
                                 subtitle='Uploaded objects will be encrypted to this level.'
                                 subtitleVariant='body2' pb={0} mt={3}
                                 action={<Grid container justifyContent='center'>
                                   <SuccessMessageComponent show={showEncryptionTypeSavedMessage}
                                                            setShow={setShowEncryptionTypeSavedMessage} mr={2}/>
                                 </Grid>}/>
                  </Grid>
                  <Grid item md={open ? 12 : 8} xs={12}>
                    <FormControlRadioGroup name='encryptionType' value={props.encryptionType}
                                           onChange={updateEncryptionType}
                                           defaultValue='SSE_S3'
                                           options={[{
                                             value: 'SSE_S3', label: 'SSE-S3'
                                           }, {
                                             value: 'NONE', label: 'No Encryption'
                                           }, {
                                             value: 'SSE_KMS', label: 'KMS Key',
                                           }]}
                    />
                    {props.encryptionType === 'SSE_KMS' &&
                    <InlineEditTextField name='encryptionId' value={props.encryptionId} autoComplete='off'
                                         style={{paddingLeft: '28px', marginTop: '-8px'}}
                                         required={props.encryptionType === 'SSE_KMS'}
                                         save={updateEncryptionId} updateMessage='Saved'
                                         placeholder='Enter Key ARN' errorMessage={errors.encryptionId}/>
                    }
                  </Grid>
                </Grid>

                <Grid container item md={open ? 12 : 8}>
                  <PageSection title='Cloud Connection Credentials' mt={4} pb={0} titleVariant='body1'
                               subtitle='Credentials used to access this cloud connection.' subtitleVariant='body2'
                               action={<Grid container justifyContent='center'>
                                 <SuccessMessageComponent show={showSavedMessage} setShow={setShowSavedMessage} mr={2}/>
                               </Grid>}
                  />
                  <FormControlRadioGroup name='useInstanceCredentials' value={props.useInstanceCredentials}
                                         onChange={updateInstanceCredentialsType}
                                         options={[
                                           ...(props.showInstanceCredentialsOption ?
                                               [{value: true, label: 'Use instance profile credentials'}] :
                                               []), {
                                             value: false, label: 'Use unique credentials'
                                           }]}
                  />
                </Grid>
                {!props.useInstanceCredentials &&
                <Grid container item md={open ? 12 : 8}>
                  <div style={{width: '100%', paddingLeft: '28px', marginTop: '-16px'}}>
                    <InlineEditTextField name='accessKeyId' label='AWS Access Key ID' value={props.keyId}
                                         required={!props.useInstanceCredentials} autoComplete='off'
                                         save={(event, data) => handleInstanceCredentials(event, data)}
                                         helperText='This AWS ID is used for this cloud connection.'
                                         placeholder='Enter Key ID' updateMessage='Saved'
                                         errorMessage={errors.accessKeyId}/>
                    <InlineEditTextField name='accessSecret' label='AWS Access Secret' autoComplete='off'
                                         required={!props.useInstanceCredentials && !props.accessSecretSet}
                                         emptyText={`${props.accessSecretSet ? '**********' : 'None'}`}
                                         value={props.keySecret} type='password' updateMessage='Saved'
                                         save={(event, data) => handleInstanceCredentials(event, data)}
                                         placeholder={`${props.accessSecretSet ? '**********' : 'Enter secret for key ID'}`}
                                         helperText='This AWS access secret is used for this cloud connection.'
                                         errorMessage={errors.accessSecret}/>
                  </div>
                </Grid>
                }
                {!isEmpty(props.connectivity) &&
                <Grid container item md={open ? 12 : 8}>
                  <TestConnectionResultTable provider={props.cloudProvider} connectivity={props.connectivity}
                                             connectionId={props.id} setErrors={props.setErrors}/></Grid>
                }
              </Grid>
            </form>
        }
      </HelpSystemContext.Consumer>
  );
}

AwsCloudConnectionEditForm.propTypes = {
  name: PropTypes.string,
  notes: PropTypes.string,
  basePrefix: PropTypes.string,
  keyId: PropTypes.string,
  cloudProvider: PropTypes.string,
  setKeyId: PropTypes.func,
  keySecret: PropTypes.string,
  accessSecretSet: PropTypes.bool,
  region: PropTypes.string,
  useInstanceCredentials: PropTypes.bool,
  setInstanceCredentials: PropTypes.func,
  encryptionType: PropTypes.string,
  setEncryptionType: PropTypes.func,
  encryptionId: PropTypes.string,
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
  errors: PropTypes.object,
  setErrors: PropTypes.func,
  connectivity: PropTypes.object,
  showInstanceCredentialsOption: PropTypes.bool,
};

AwsCloudConnectionEditForm.defaultProps = {
  accessSecretSet: false,
};

export default AwsCloudConnectionEditForm;
