import React from 'react';
import PropTypes from 'prop-types';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

function Date(props) {
    const {label} = {...props};
    const [error, setError] = React.useState(null);

    const errorMessage = React.useMemo(() => {
        switch (error) {
            case 'disableFuture': {
                return 'Please select a date in the past';
            }

            case 'invalidDate': {
                return 'Your date is not valid';
            }

            default: {
                return '';
            }
        }
    }, [error]);

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
                label={label}
                inputFormat='MM/DD/YYYY h:mm a'
                disableMaskedInput
                defaultValue={props.value}
                onChange={newDate => {
                    props.onChange(newDate);
                }}
                onError={setError}
                toolbarPlaceholder='Select Date'
                disableFuture
                slotProps={{
                    textField: {
                        helperText: errorMessage
                    }
                }}
             />
        </LocalizationProvider>
    );
}

Date.propTypes = {
    value: PropTypes.any,
    name: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    validate: PropTypes.func,
    error: PropTypes.any,
};

Date.defaultProps = {
    name: '',
    label: '',
    onChange: () => {},
};

export default Date;
