import React, {useEffect, useState} from 'react';
import axios from 'axios';
import toast from 'toasted-notes';
import {useDispatch} from 'react-redux';
import {isEmpty} from '../util/helpers';
import api_routes from '../util/api_routes';
import httpStatus from '../util/http_status';
import {handleErrors} from '../util/errorHandler';
import CustomAlert from '../component/common/CustomAlert';
import {authenticationService} from '../container/auth/authenticationService';
import {setAuthenticated} from '../action';

const initDependencies = {
    triggerRefresh: false,
};

function useAdminUsers({dependencies = initDependencies}) {

    const dispatch = useDispatch();

    const {triggerRefresh} = dependencies;
    const [adminUsers, setAdminUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(function loadAdminUsers() {
        const fetchData = async function fetchAdminUsers() {
            try {
                const result = await axios.get(api_routes.adminUser.endpoint);
                setAdminUsers(result.data.content)
            } catch (error) {
                const tempErrors = handleErrors(error.response);
                toast.notify(({onClose}) =>
                    <CustomAlert type='error' message={tempErrors.msg} onClose={onClose}/>);
                // Let the app know that this user is no longer authenticated (this will trigger the login page)
                if (!isEmpty(error.response) && error.response.status === httpStatus.unAuthorized) {
                    authenticationService.clearTokenInfo();
                    dispatch(setAuthenticated(false));
                }
            } finally {
                setIsLoading(false);
            }
        };
        // noinspection JSIgnoredPromiseFromCall
        fetchData();
    }, [dispatch]);

    return {isLoading, adminUsers};
}

export default useAdminUsers;