import React from 'react';
import PropTypes from 'prop-types';
import {Grid} from '@mui/material';
import {useSelector} from 'react-redux';
import SSHKeyTable from './formFields/SSHKeyTable';
import HomeDirectory from './formFields/HomeDirectory';
import UidAndGidHeader from './formFields/UidAndGidHeader';
import PasswordSelector from './formFields/PasswordSelector';
import IpAllowListTable from './formFields/IpAllowListTable';
import InlineEditTextField from '../common/InlineEditTextField';
import AdvancedSectionExpansion from '../common/AdvancedSectionExpansion';
import {isEmpty} from '../../util/helpers';
import HelpSystemContext from '../../context/HelpSystemContext';

// noinspection FunctionNamingConventionJS
function UserEditForm(props) {

    const systemInfo = useSelector(function getSystemInfo(state) {
        return state.systemInfo;
    });

    const updateUid = (event, data) => {
        if (isEmpty(data.uid)) {
            data.uid = systemInfo.defaultUid;
        }
        props.handleSubmit(event, data)
    }

    const updateGid = (event, data) => {
        console.log(data);
        if (isEmpty(data.gid)) {
            data.gid = systemInfo.defaultGid;
        }
        props.handleSubmit(event, data)
    }

    return (
        <HelpSystemContext.Consumer>
            {
                (open) =>
                    <form>
                        <Grid container justifyContent='center'>
                            <Grid container item md={open ? 12 : 8}>
                                <InlineEditTextField name='username' label='Username' labelVariant='body1'
                                                     value={props.username}
                                                     save={(event, data) => props.handleSubmit(event, data)}
                                                     width={open ? '100' : '50'} updateMessage='Saved' required/>
                            </Grid>
                            <Grid container item md={open ? 12 : 8}>
                                <InlineEditTextField name='notes' label='User Notes' labelVariant='body1'
                                                     value={props.notes}
                                                     showCount updateMessage='Saved' multiline
                                                     inputProps={{maxLength: 1000}} width='100'
                                                     save={(event, data) => props.handleSubmit(event, data)}/>
                            </Grid>

                            <Grid container item md={open ? 12 : 8}>
                                <HomeDirectory folderPermissions={props.folderPermissions} homeFolder={props.homeFolder}
                                               id={props.id} handleSubmit={props.handleSubmit}/>
                            </Grid>

                            <Grid container item md={open ? 12 : 8}>
                                <SSHKeyTable userId={props.id} publicKeys={props.publicKeys}/>
                            </Grid>

                            <Grid container item md={open ? 12 : 8}>
                                <AdvancedSectionExpansion mt={3}>
                                    <Grid item>
                                        <PasswordSelector usesPassword={props.usesPassword}
                                                          isPasswordSet={props.isPasswordSet}
                                                          handleChange={props.updatePassword}/>
                                    </Grid>

                                    <Grid item>
                                        <IpAllowListTable ipAllowListEnabled={props.ipAllowListEnabled}
                                                          allowedIps={props.allowedIps} userId={props.id}/>
                                    </Grid>

                                    <Grid item>
                                        <UidAndGidHeader/>
                                        <Grid item sm={7} md={4}>
                                            <InlineEditTextField name='uid' label='UID' labelVariant='body1'
                                                                 value={!isEmpty(props.uid) ? props.uid.toString() : ''}
                                                                 type='number' updateMessage='Saved'
                                                                 save={(event, data) => updateUid(event, data)}
                                                                 width='100' helperText='Enter UID value'
                                            />
                                        </Grid>
                                        <Grid item sm={7} md={4}>
                                            <InlineEditTextField name='gid' label='GID' labelVariant='body1'
                                                                 value={!isEmpty(props.gid) ? props.gid.toString() : ''}
                                                                 type='number' updateMessage='Saved'
                                                                 save={(event, data) => updateGid(event, data)}
                                                                 width='100' helperText='Enter GID value'
                                            />
                                        </Grid>
                                    </Grid>

                                </AdvancedSectionExpansion>
                            </Grid>
                        </Grid>
                    </form>
            }
        </HelpSystemContext.Consumer>
    );
}

UserEditForm.propTypes = {
    username: PropTypes.string,
    notes: PropTypes.string,
    publicKeys: PropTypes.array,
    password: PropTypes.string,
    allowedIps: PropTypes.array,
    ipAllowListEnabled: PropTypes.bool,
    handleSubmit: PropTypes.func,
    homeFolder: PropTypes.any,
    folderPermissions: PropTypes.any,
    isPasswordSet: PropTypes.bool,
    usesPassword: PropTypes.bool,
};

UserEditForm.defaultProps = {};

export default UserEditForm;
