import React, {useState} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText,
    Typography,
    IconButton,
    Input,
    Link,
    Breadcrumbs,
    useTheme,
    MenuItem,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {Search as SearchIcon} from '@mui/icons-material';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFolderOpen} from '@fortawesome/free-regular-svg-icons';
import {faFolder as solidFolderIcon} from '@fortawesome/free-solid-svg-icons';
import useFolder from '../../../api/useFolder';
import {isEmpty, isEmptyObject} from '../../../util/helpers';
import FormControlSelect from '../../common/FormControlSelect';
import CircularProgressButton from '../../common/CircularProgressButton';
import {StyledRowIcon} from '../../folder/FolderListComponent';
import { grey } from '@mui/material/colors';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
    iconButton: {
        padding: theme.spacing(1),
    },
}));

const StyledFolderRows = styled.div`
    padding: 8px;
    cursor: pointer;
    &:hover {
        cursor: pointer;
        background-color: ${props => props.theme.palette.action.hover};
    }
    border-bottom: 1px solid ${grey[300]}
`

// noinspection FunctionNamingConventionJS
function HomeDirectoryModal(props) {

    const theme = useTheme();
    const classes = useStyles(theme);
    const {handleCloseModal, save} = {...props};
    const [folderId, setFolderId] = useState(props.currentHomeFolder);
    const [searchBox, setSearchBox] = useState('');
    const [permission, setPermission] = useState(props.currentHomeFolderPermission);

    const {folder} = useFolder({
        params: {folderId, includeSubFolders: true, searchString: searchBox}
    });

    const saveHomeFolder = async function saveHomeFolder(event) {
        await save(event, {homeFolderId: folderId, permission});
        handleCloseModal();
    }

    const displayAbsolutePath = function displayAbsolutePathOnHomeDirModal(folder) {
        const folderNames = folder?.absolutePath?.split('/');
        const folderIds = folder?.ltreePath?.split('.');
        let foldersArray = folderIds?.map(function (id, index) {
            return {id, name: folderNames[index]}
        });
        return (
            <Breadcrumbs aria-label='breadcrumb' style={{margin: '10px'}}>
                {foldersArray?.map((item) => (
                    <Link key={item.id} color='inherit' style={{cursor: 'pointer'}}
                          onClick={() => setFolderId(item.id)}>
                        {isEmpty(item.name) ? <FontAwesomeIcon icon={faFolderOpen}/> : item.name}
                    </Link>
                ))};
            </Breadcrumbs>
        );
    };

    const goToSubFolder = function goToSubFolder(subFolderId) {
        setFolderId(subFolderId);
        setSearchBox('');
    };

    const goToParentFolder = function goToParentFolder(folder) {
        const folderIds = folder?.ltreePath?.split('.') || [];
        let parentId = folderIds[folderIds.length - 2];
        if (isEmpty(parentId)) {
            parentId = 0;
        }
        setFolderId(parentId);
    };

    return (
        <div className={classes.root}>
            <DialogTitle>Choose Home Directory</DialogTitle>
            <DialogContent>

                <DialogContentText>
                    Select a folder in the folder tree and press apply to set the home directory for the user.
                </DialogContentText>

                <IconButton size='small' type='submit' className={classes.iconButton} aria-label='search users'>
                    <SearchIcon fontSize='small'/>
                </IconButton>
                <Input
                    value={searchBox}
                    onChange={(e) => setSearchBox(e.target.value)}
                    placeholder='Find folder'
                    inputProps={{'aria-label': 'find folder'}}
                />
                {displayAbsolutePath(folder)}
                <StyledFolderRows theme={theme} style={{fontWeight: '900'}} key='-1'
                                  onClick={() => goToParentFolder(folder)}>..</StyledFolderRows>
                <div className='scrollable'>
                    {!isEmptyObject(folder) && folder.folders.map(item =>
                        <StyledFolderRows theme={theme} onClick={() => goToSubFolder(item.id)} key={item.id || 0}>
                            <StyledRowIcon size='lg' icon={solidFolderIcon}
                                           color={theme.palette.primary.main}/> {item.name}
                        </StyledFolderRows>)
                    }
                    {!isEmptyObject(folder) && (folder.folders.length === 0) &&
                    <StyledFolderRows theme={theme} style={{textAlign: 'center'}} key='-2'>No folders to
                        display</StyledFolderRows>
                    }
                </div>
                <div style={{padding: '16px 0px 8px', wordWrap: 'break-word'}}>
                    <Typography variant='caption'>Selected Folder</Typography>
                    <Typography variant='body2' style={{wordBreak: 'break-all'}}>
                        <StyledRowIcon size='lg' icon={solidFolderIcon} color={theme.palette.primary.main}/>
                        {folder.absolutePath}
                    </Typography>
                </div>
                <FormControlSelect name='permission' width='100' requred type='select'
                                   defaultValue='READ_WRITE' label='Permissions'
                                   onChange={(event) => setPermission(event.target.value)} value={permission}
                                   helperText={'This is the action the user can take on this folder ' +
                                   'and subfolders when connecting via SFTP.'}
                >
                    <MenuItem value={"READ_WRITE"}>Read / Write</MenuItem>
                    <MenuItem value={"READ"}>Read Only</MenuItem>
                    <MenuItem value={"WRITE"}>Write Only</MenuItem>
                    <MenuItem value={"NONE"}>None</MenuItem>
                </FormControlSelect>
            </DialogContent>
            <DialogActions>
                <CircularProgressButton theme={theme} label={props.applyButtonText} size='small'
                                        buttonTextTransform='none' onClick={saveHomeFolder} mt={0}/>
                <CircularProgressButton theme={theme} label='Cancel' size='small' variant='text'
                                        buttonTextTransform='none' onClick={handleCloseModal} mt={0}/>
            </DialogActions>
        </div>
    );
}

HomeDirectoryModal.propTypes = {
    handleCloseModal: PropTypes.func,
    save: PropTypes.func,
    currentHomeFolder: PropTypes.any,
    currentHomeFolderPermission: PropTypes.string,
    applyButtonText: PropTypes.string,
};

HomeDirectoryModal.defaultProps = {
    currentHomeFolder: 0,
    currentHomeFolderPermission: 'READ_WRITE',
    applyButtonText: 'Apply'
};

export default HomeDirectoryModal;
