import React, {useState} from 'react';
import PropTypes from 'prop-types';
import FormControlInput from "./FormControlInput";
import {IconButton, InputAdornment, Tooltip, Typography} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCopy as copyIcon} from "@fortawesome/free-regular-svg-icons";
import {HelpOutline, Visibility, VisibilityOff} from "@mui/icons-material";
import toast from "toasted-notes";
import CustomAlert from "./CustomAlert";
import styled from "styled-components";

const tooltipLeaveDelay = 100;
const StyledLabelTooltip = styled(Tooltip)`
  margin-left: 5px;
  &:hover {
      cursor: pointer;
  }
`;

// noinspection FunctionNamingConventionJS
function PasswordField(props) {

  const {password, setPassword} = props;
  const {handleChange} = props;
  const {allowCopy} = props;
  const [fieldType, setFieldType] = useState('password');
  const [showPassword, setShowPassword] = useState(false);
  const [visibilityIcon, setVisibilityIcon] = useState(<VisibilityOff fontSize='small'/>);
  const [transition, setTransition] = useState(false);
  const [color, setColor] = useState('primary');

  const copyPassword = () => {
    navigator.clipboard.writeText(password).then(() => {
      setColor('secondary');
      setTimeout(() => setTransition(true), 2000);
      toast.notify(({onClose}) => <CustomAlert type='success' message='Password copied!' onClose={onClose}/>);
      setTimeout(function revertColor() {
        setColor('primary')
      }, 5000);
      setTimeout(function revertColor() {
        setTransition(false);
      }, 8000);
    });
  }

  const togglePassword = function togglePasswordVisibilityOnUserForm() {
    // If we are switching from showPassword to !showPassword
    if (showPassword) {
      setVisibilityIcon(<VisibilityOff fontSize='small'/>);
      setFieldType('password');
    } else {
      setVisibilityIcon(<Visibility fontSize='small'/>);
      setFieldType('text');
    }
    setShowPassword(!showPassword);
  };

  return (
      <FormControlInput value={password} width={props.width} required={props?.required} label={props.label}
                        name={props.name ? props.name : 'password'} error={props.error}
                        errorMessage={props.errorMessage}
                        {...(props.margin && {margin: props.margin})}
                        {...(props.removeBackground && {removeBackground: props.removeBackground})}
                        onChange={(event) => {
                          if (props.creatingUser) {
                            setPassword(event.target.value);
                            handleChange(event);
                          } else if (handleChange) {
                            handleChange(event)
                          } else {
                            setPassword(event.target.value);
                          }
                        }}
                        placeholder={props.placeholder} type={fieldType}
                        endAdornment={<InputAdornment position='end'>

                          {allowCopy &&
                              <IconButton variant='body2' color={color} onClick={copyPassword} size="large">
                                <FontAwesomeIcon icon={copyIcon} size={"xs"}
                                                 color={color}
                                                 style={
                                                   transition ?
                                                       {
                                                         transitionProperty: 'color, border',
                                                         transitionDuration: '3s',
                                                         transitionTimingFunction: 'linear',
                                                         cursor: 'pointer'
                                                       } : {
                                                         transitionProperty: 'color, border',
                                                         transitionTimingFunction: 'linear',
                                                         cursor: 'pointer'
                                                       }
                                                 }
                                />
                              </IconButton>
                          }
                          <IconButton edge='end' onClick={togglePassword}
                                      aria-label='toggle password visibility'
                                      size='small'
                          >
                            {visibilityIcon}
                          </IconButton>
                          {props.tooltip &&
                              <StyledLabelTooltip
                                  title={
                                    <Typography variant={"body2"}>{props.tooltip} </Typography>}
                                  leaveDelay={tooltipLeaveDelay}>
                                <HelpOutline fontSize='small'/>
                              </StyledLabelTooltip>
                          }
                        </InputAdornment>}
      />
  );
}

PasswordField.propTypes = {
  password: PropTypes.string,
  setPassword: PropTypes.func,
  handleChange: PropTypes.func,
  width: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  creatingUser: PropTypes.bool,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  allowCopy: PropTypes.bool,
  tooltip: PropTypes.string,
  name: PropTypes.string,
  margin: PropTypes.string,
  removeBackground: PropTypes.bool
};
PasswordField.defaultProps = {
  creatingUser: false
};
export default PasswordField;