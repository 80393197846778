import React, { useEffect, useState } from 'react';
import FormControlInput from '../common/FormControlInput';
import { Grid } from '@mui/material';
import CustomCardHeader from '../common/CustomCardHeader';
import CircularProgressButton from '../common/CircularProgressButton';
import useCloudLogConnection from '../../api/useCloudLogConnection';
import ProgressFormCard from '../common/ProgressFormCard';
import SuccessMessageComponent from '../common/SuccessMessageComponent';
import { AWS, AZURE, GCP } from '../../util/cloud_providers';

const formText = {
    [AZURE]: {
        resourceId: {
            label: 'Workspace ID',
            helperText: 'Enter Workspace ID from Log Analytics.',
            placeholder: 'Enter a Workspace ID',
        },
        applicationLogName: {
            label: 'Application Log Table',
            helperText: 'Application logs table name.',
            placeholder: 'ApplicationLog_CL',
        },
        auditLogName: {
            label: 'Audit Log Table',
            helperText: 'Audit logs table name.',
            placeholder: 'AuditLog_CL',
        }
    },
    [AWS]: {
        resourceId: {
            label: 'Group Name',
            helperText: 'Enter a group name from Cloud Watch.',
            placeholder: 'Enter a group name',
        }
    },
    [GCP]: {
        applicationLogName: {
            label: 'Application Log Name',
            helperText: 'Provide the log name for application logs.',
            placeholder: 'Enter application log name',
        },
        auditLogName: {
            label: 'Audit Log Name',
            helperText: 'Provide the log name for audit logs.',
            placeholder: 'Enter audit log name',
        }
    }

}

// noinspection FunctionNamingConventionJS
function CloudLogConnectionInfoComponent(props) {

    const {isLoading, cloudLogConnInfo, updateCloudLogConnectionInfo} = useCloudLogConnection();

    const [cloudLogConnInfoState, setCloudLogConn] = useState(cloudLogConnInfo);
    const [submitting, setSubmitting] = useState(false);
    const [showSavedMessage, setShowSavedMessage] = useState(false);

    useEffect(() => {
        setCloudLogConn(cloudLogConnInfo);
    }, [cloudLogConnInfo]);

    const handleChange = (event) => {
        setCloudLogConn({...cloudLogConnInfoState, [event.target.name]: event.target.value});
    }

    const handleSubmit = async function handleUpdateCloudLogInformation(event) {
        event.preventDefault();
        setSubmitting(true);
        try {
            await updateCloudLogConnectionInfo(cloudLogConnInfoState);
            setShowSavedMessage(true);
        } catch (error) {

        }
        setSubmitting(false);
    };

    return (
        <ProgressFormCard loadingContent={isLoading}
                          header={
                              <CustomCardHeader
                                  title={'Cloud Log Connection Info'}
                                  titleVariant={'h6'}
                                  style={{paddingBottom: '0px'}}
                                  action={<Grid container justifyContent='center'>
                                      <SuccessMessageComponent show={showSavedMessage}
                                                               setShow={setShowSavedMessage} mr={2}/>
                                  </Grid>}
                              />
                          }
                          content={
                              <form onSubmit={handleSubmit}>
                                  {(props.provider === AZURE || props.provider === AWS) &&
                                      <Grid container>
                                          <FormControlInput value={cloudLogConnInfoState.resourceId}
                                                            width='50'
                                                            label={formText[props.provider].resourceId.label}
                                                            name='resourceId'
                                                            placeholder={formText[props.provider].resourceId.placeholder}
                                                            onChange={handleChange}
                                                            helperText={formText[props.provider].resourceId.helperText}
                                          />
                                      </Grid>
                                  }
                                  {(props.provider === AZURE) &&
                                      <Grid container>
                                          <FormControlInput value={cloudLogConnInfoState.applicationLogName}
                                                            width='50'
                                                            label={formText[props.provider].applicationLogName.label}
                                                            name='applicationLogName'
                                                            placeholder={formText[props.provider].applicationLogName.placeholder}
                                                            onChange={handleChange}
                                                            helperText={formText[props.provider].applicationLogName.helperText}
                                          />
                                      </Grid>
                                  }
                                  {(props.provider === AZURE) &&
                                      <Grid container>
                                          <FormControlInput value={cloudLogConnInfoState.auditLogName}
                                                            width='50'
                                                            label={formText[props.provider].auditLogName.label}
                                                            name='auditLogName'
                                                            placeholder={formText[props.provider].auditLogName.placeholder}
                                                            onChange={handleChange}
                                                            helperText={formText[props.provider].auditLogName.helperText}
                                          />
                                      </Grid>
                                  }
                                  <Grid container>
                                      <FormControlInput type='number'
                                                        value={cloudLogConnInfoState.logLimit}
                                                        width='33'
                                                        label='Log Limit'
                                                        name='logLimit'
                                                        placeholder='100'
                                                        onChange={handleChange}
                                                        required
                                                        helperText='Number of log entries to view at a time.'
                                      />
                                  </Grid>
                                  <Grid container justifyContent='flex-start'>
                                      <CircularProgressButton type='submit' size='small' label='Save'
                                                              mt={2} mr={1} inProgress={submitting}/>
                                  </Grid>
                              </form>
                          }
        />

    );
}

CloudLogConnectionInfoComponent.propTypes = {};

CloudLogConnectionInfoComponent.defaultProps = {};

export default CloudLogConnectionInfoComponent;
