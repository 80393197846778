import React, {useEffect, useState} from 'react';
import _ from 'lodash';
import axios from 'axios';
import toast from 'toasted-notes';
import {useDispatch} from 'react-redux';
import {green, red} from '@mui/material/colors';
import {FormControl, Grid, MenuItem} from '@mui/material';
import {StyledSelect,} from '../../common/styled';
import CustomAlert from '../../common/CustomAlert';
import PageSection from '../../common/PageSection';
import InlineEditTable from '../../common/InlineEditTable';
import FormControlInput from '../../common/FormControlInput';
import CircularProgressButton from '../../common/CircularProgressButton';
import SuccessMessageComponent from '../../common/SuccessMessageComponent';
import {authenticationService} from '../../../container/auth/authenticationService';
import {StyledChip} from '../../user/formFields/SSHKeyTable';
import api_routes from '../../../util/api_routes';
import httpStatus from '../../../util/http_status';
import {isEmpty, isEmptyObject} from '../../../util/helpers';
import {convertErrorsToObject, handleErrors} from '../../../util/errorHandler';
import {setAuthenticated} from '../../../action';
import useAdminUsers from '../../../api/useAdminUsers';
import PasswordField from "../../common/PasswordField";

function displayRow(data) {
  const password = data.isPasswordSet ? '**********' : '';
  let statusChip;
  if (data.enabled) {
    statusChip = <StyledChip size='small' label='Enabled' style={{backgroundColor: green['100']}}/>;
  } else {
    statusChip = <StyledChip size='small' label='Disabled' style={{backgroundColor: red['100']}}/>;
  }
  const enabled = statusChip;
  return [data.username, data.email, password, enabled];
}

function editRow({data, updateData, errors}) {
  return [
    <FormControlInput errorMessage={errors.username} name='username' margin='none' removeBackground
                      autoFocus value={data.username} onChange={updateData} placeholder='Username'/>,
    <FormControlInput errorMessage={errors.email} name='email' margin='none' removeBackground type='email'
                      value={data.email} onChange={updateData} placeholder='Email Address'/>,
    <PasswordField errorMessage={errors.password} margin='none' removeBackground
                   password={data.password} handleChange={updateData} placeholder='One Time Password'/>,
    <FormControl>
      <StyledSelect variant={'standard'} value={data.enabled} name='enabled' onChange={updateData}>
        <MenuItem value={true}>Enabled</MenuItem>
        <MenuItem value={false}>Disabled</MenuItem>
      </StyledSelect>
    </FormControl>
  ];
}

function addRow(data, updateData, errors) {
  return [
    <FormControlInput value={data.username} width='100' name='username' margin='none'
                      removeBackground errorMessage={errors.username} autoFocus variant={'standard'}
                      placeholder='Username' onChange={updateData}/>,
    <FormControlInput value={data.email} width='100' name='email' margin='none' type='email'
                      removeBackground placeholder='Email Address' errorMessage={errors.email}
                      onChange={updateData} variant={'standard'} />,
    <PasswordField password={data.password} width='100' margin='none'
                   errorMessage={errors.password} removeBackground variant={'standard'}
                   placeholder='One Time Password' handleChange={updateData}/>,
    <FormControl>
      <StyledSelect value={data.enabled} name='enabled' onChange={updateData} variant={'standard'}>
        <MenuItem value={true}>Enabled</MenuItem>
        <MenuItem value={false}>Disabled</MenuItem>
      </StyledSelect>
    </FormControl>
  ];
}

// noinspection FunctionNamingConventionJS
function SettingsCompAdminUsers(props) {
  const dispatch = useDispatch();
  const {triggerRefresh} = props;
  const {adminUsers} = useAdminUsers({
    dependencies: {
      triggerRefresh
    }
  });

  const [users, setUsers] = useState(adminUsers);
  const [showAddForm, setShowAddForm] = useState(false);
  const [showSavedMessage, setShowSavedMessage] = useState(false);

  useEffect(() => {
    setUsers(adminUsers);
  }, [adminUsers]);

  const handleUnauthenticatedUser = function handleUnauthenticatedUser(error) {
    // Let the app know that this user is no longer authenticated (this will trigger the login page)
    if (!isEmpty(error.response) && error.response.status === httpStatus.unAuthorized) {
      authenticationService.clearTokenInfo();
      dispatch(setAuthenticated(false));
      const tempErrors = handleErrors(error.response);
      toast.notify(({onClose}) =>
          <CustomAlert type='error' message={tempErrors.msg} onClose={onClose}/>);
    }
  }

  const handleSaveAdminUser = async function handleSaveAdminUserOnSettingsScreen(newUser) {
    validateUser(newUser);
    try {
      let response = await axios.post(api_routes.adminUser.endpoint, newUser);
      setUsers(users => [...users, response.data]);
      setShowSavedMessage(true);
    } catch (error) {
      handleUnauthenticatedUser(error);
      if (!isEmpty(error.response)) {
        let tempErrors;
        switch (error.response.status) {
          case httpStatus.badRequest:
            tempErrors = convertErrorsToObject(error.response);
            throw tempErrors;
          case httpStatus.conflict:
            tempErrors = {username: 'Duplicate entry.'};
            throw tempErrors;
          default:
        }
      }
    }
  };

  const handleDeleteAdminUser = async function handleDeleteAdminUserOnSettingsScreen({itemToRemove: user}) {
    try {
      await axios.delete(`${api_routes.adminUser.endpoint}/${user.id}`);
      let tempUsers = _.remove(users, function (u) {
        return u !== user;
      });
      setUsers(tempUsers);
      setShowSavedMessage(true);
    } catch (error) {
      handleUnauthenticatedUser(error);
      if (!isEmpty(error.response) && (error.response.status === httpStatus.methodNotAllowed
          || error.response.status === httpStatus.methodNotAllowed)) {
        toast.notify(({onClose}) =>
            <CustomAlert type='error' message={'Could not delete admin user!'} onClose={onClose}/>);
      }
    }
  };

  const validateUser = function validateAdminUser(user) {
    let errors = {};
    if (isEmpty(user.username)) {
      errors.username = 'Username is required.';
    }
    if (!isEmpty(user.email) && !(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        user.email))) {
      errors.email = 'Wrong email format.';
    }
    if (!isEmptyObject(errors)) {
      throw errors;
    }
  }

  const updateUser = async function updateAdminUser({index, itemToEdit: data}) {
    validateUser(data);
    let user = users[index];
    let payload;
    if (isEmpty(data.password)) {
      payload = {...data, password: null, isPasswordSet: false};
    } else {
      payload = {...data, isPasswordSet: true};
    }
    try {
      let response = await axios.put(`${api_routes.adminUser.endpoint}/${user.id}`, payload);
      let tempUsers = _.map(users, function updateUsers(user) {
        if (user.id === data.id) {
          return response.data;
        }
        return user;
      });
      setUsers(tempUsers);
      setShowSavedMessage(true);
    } catch (error) {
      handleUnauthenticatedUser(error);
      if (!isEmpty(error.response)) {
        let tempErrors;
        switch (error.response.status) {
          case httpStatus.badRequest:
            tempErrors = convertErrorsToObject(error.response);
            if (!isEmpty(tempErrors.enabled)) {
              toast.notify(({onClose}) =>
                  <CustomAlert type='error' message={tempErrors.enabled} onClose={onClose}/>);
            }
            throw tempErrors;
          case httpStatus.conflict:
            tempErrors = {username: 'Duplicate entry.'};
            throw tempErrors;
          default:
        }
      }
    }
  };

  // noinspection ConditionalExpressionJS
  return (<>
    <PageSection title='Admin Web Users'
                 subtitle='The following admin users have access to the admin web app.'
                 action={<Grid container alignItems='center'>
                   <SuccessMessageComponent show={showSavedMessage} setShow={setShowSavedMessage} mr={2}/>
                   <Grid item>
                     <CircularProgressButton mt={0} size='small' label='Add User' buttonTextTransform='none'
                                             onClick={() => setShowAddForm(true)}/>
                   </Grid>
                 </Grid>

                 }
    />

    <InlineEditTable
        header={['Username', 'Email', 'One Time Password', 'Status']}
        data={users}
        displayRow={displayRow}
        editRow={editRow}
        columnWidths={[25, 25, 20, 15, 15]}
        update={updateUser}
        remove={handleDeleteAdminUser}
        showAddRow={showAddForm}
        setShowAddRow={setShowAddForm}
        addRow={addRow}
        itemToCreateInitState={{username: '', email: '', password: '', enabled: true}}
        create={handleSaveAdminUser}
        deleteModalTitle='Delete Admin User'
        deleteModalField='username'
    />

  </>);
}

SettingsCompAdminUsers.propTypes = {};

SettingsCompAdminUsers.defaultProps = {};

export default SettingsCompAdminUsers;
