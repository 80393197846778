import folderPermissions from './folder_permissions_map';

function isEmpty(a) {
    return a === undefined || a === null || a.toString().trim() === '';
}

function isUndefined(a) {
    return a === undefined;
}

function isEmptyObject(a) {
    return a === undefined || a === null || Object.keys(a).length === 0
}

function truncateString(text, length) {
    let str = text;
    if (!isEmpty(text) && text.length > length + 3) {
        str = text.slice(0, length) + "...";
    }
    return str;
}

function truncateStringMiddle(text, length) {
    let str = text;
    if (!isEmpty(text) && text.length > length + 3) {
        str = text.slice(0, Math.floor(length / 2)) + "..." +
            text.slice(text.length - Math.floor(length / 2), text.length);
    }
    return str;
}

function getFolderPermissionString(key) {
    let value = '';
    if (!isEmpty(key)) {
        let lowerCaseKey = key.toLowerCase();
        value = folderPermissions[lowerCaseKey];
    }
    return value;
}

export { isEmpty, isEmptyObject, truncateString, getFolderPermissionString, truncateStringMiddle, isUndefined };
