import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Box, Card, CardContent, Grid, Tab, Tabs, Typography, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import routes from '../../util/routes';
import api_routes from '../../util/api_routes';
import { HA, SINGLE } from '../../util/architecture';
import PageSection from '../common/PageSection';
import CircularProgressButton from '../common/CircularProgressButton';
import { downloadBackupArtifact } from '../settings/backup/SettingsBackupComponent';
import LogContainer from '../../container/diagnostics/LogContainer';
import CloudLogConnectionInfoComponent from './CloudLogConnectionInfoComponent';
import useAuditLogs from '../../api/useAuditLogs';
import useApplicationLogs from '../../api/useApplicationLogs';

function TabPanel(props) {
    const {children, value: tab, index, ...other} = props;
    return (
        <div
            role='tab-panel'
            hidden={tab !== index}
            id={`sftpgw-tab-panel-${index}`}
            aria-labelledby={`sftpgw-tab-${index}`}
            {...other}
        >
            {tab === index && (
                <Box sx={{paddingTop: 3}}>
                    <>{children}</>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

// noinspection AnonymousFunctionJS
const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
    },
    card: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(4),
    },
}));

const StyledSystemInfoTable = styled.table`
    border-spacing: unset;
    & tr:nth-child(odd) {
        background-color: ${props => props.theme.palette.action.hover};
    }
    & tr:nth-child(1) {
        & th, & td {
            border-top: 1px solid rgba(224, 224, 224, 1);
        }
    }
    & th, & td {
        border-bottom: 1px solid rgba(224, 224, 224, 1);
    }
    & th {
        text-align: left;
        font-weight: normal;
        text-transform: uppercase;
        padding: ${props => `${props.theme.spacing(.5)}px ${props.theme.spacing(1)}px`};
    }
    & td {
        padding: ${props =>
    `${props.theme.spacing(.5)} ${props.theme.spacing(1)} ${props.theme.spacing(.5)} ${props.theme.spacing(3)}`};
    }
    & td:last-child {
        text-align: right;
    }
`;

function a11yProps(index) {
    return {
        id: `sftpgw-tab-${index}`,
        'aria-controls': `sftpgw-tab-panel-${index}`,
    };
}

// noinspection FunctionNamingConventionJS
function DiagnosticsComponent() {
    const classes = useStyles();
    const theme = useTheme();
    const [tab, setTab] = React.useState(0);
    const handleChange = (event, newValue) => {
        setTab(newValue);
    };

    const systemInfo = useSelector(function getSystemInfo(state) {
        return state.systemInfo;
    });

    const displayHaStatus = (systemInfo) => {
        let status;
        if (systemInfo.architecture === HA) {
            status = 'Yes';
        } else if (systemInfo.architecture === SINGLE) {
            status = 'No';
        }
        return status;
    }

    const supportBackUpFile = () => {
        try {
            axios.get(`${api_routes.backup.endpoint}`,
                { params: {support: true}, responseType: 'blob' }
            ).then(
                response => downloadBackupArtifact(response)
            );
        } catch (error) {
            alert("Export failed due to: " + error);
            console.error(error);
        }

    }

    return (<div className={classes.root}>
        <Grid container justifyContent='space-between' alignItems='center' wrap='nowrap'>
            <Grid item>
                <Typography variant='h5'>{routes.diagnostics.pageTitle}</Typography>
            </Grid>
        </Grid>

        <Card className={classes.card} variant='outlined'>
            <CardContent>
                <PageSection title='System Information'/>
                <Grid container>
                    <Grid item>
                        <StyledSystemInfoTable theme={theme}>
                            <tbody>
                            <tr>
                                <th>Version</th>
                                <td>{systemInfo.activeVersion}</td>
                            </tr>
                            <tr>
                                <th>Cloud Provider</th>
                                <td>{systemInfo.cloudProvider}</td>
                            </tr>
                            <tr>
                                <th>High Availability</th>
                                <td>{displayHaStatus(systemInfo)}</td>
                            </tr>
                            </tbody>
                        </StyledSystemInfoTable>
                    </Grid>
                </Grid>

                <Grid container direction='column' style={{marginTop: theme.spacing(2)}}>
                    <Grid item md={8}>
                        <Typography variant='body2'>
                            When providing support we are more effective when we can replicate your setup. Support
                            Backup
                            produces a backup file without any sensitive information like secrets and passwords. When
                            contacting
                            support please provide this file.
                        </Typography>
                    </Grid>
                    <Grid item>
                        <CircularProgressButton mt={2} size='small' label='Support Backup' buttonTextTransform='none'
                                                onClick={supportBackUpFile} fullWidth={false}/>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>

        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
            <Tabs value={tab} onChange={handleChange} aria-label='diagnostics tabs'>
                <Tab label='SFTP Activity Log' {...a11yProps(0)} />
                <Tab label='Server Log' {...a11yProps(1)} />
                <Tab label='Cloud Log Connection' {...a11yProps(2)} />
            </Tabs>
        </Box>
        <TabPanel value={tab} index={0}>
            <LogContainer useLogsEffect={useAuditLogs} title={'audit.log'}/>
        </TabPanel>
        <TabPanel value={tab} index={1}>
            <LogContainer useLogsEffect={useApplicationLogs} title={'application.log'}/>
        </TabPanel>
        <TabPanel value={tab} index={2}>
            <CloudLogConnectionInfoComponent provider={systemInfo.cloudProvider}/>
        </TabPanel>
    </div>);
}

DiagnosticsComponent.propTypes = {};

DiagnosticsComponent.defaultProps = {};

export default DiagnosticsComponent;
