import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {Grid, MenuItem,} from '@mui/material';
import {StyledSelect} from '../common/styled';
import PageSection from '../common/PageSection';
import InlineEditTable from '../common/InlineEditTable';
import SuccessMessageComponent from '../common/SuccessMessageComponent';
import {getFolderPermissionString, isEmpty} from '../../util/helpers';

function displayRowWithInheritance(folderId) {
    function displayRow(data) {
        let folderPermissionString = getFolderPermissionString(data.permission);
        if (folderId !== data.folderId) {
            folderPermissionString = `Inherited (${folderPermissionString})`
        }
        return [data.username, folderPermissionString];
    }

    return displayRow;
}

function editRowWithInheritance(folderId) {
    function EditRow({data, updateData}) {
        if (isEmpty(data.newPerms)) {
            data.newPerms = (data.folderId !== folderId) ? "INHERITED" : data.permission;
        }
        return [data.username,
            <StyledSelect name={'newPerms'}
                          variant={'standard'}
                          labelId={`${data.userId}-${data.folderId}-permission-label`}
                          id={`${data.userId}-${data.folderId}-permission`}
                          value={data.newPerms}
                          onChange={updateData}
            >
                <MenuItem value={"READ_WRITE"}>Read / Write</MenuItem>
                <MenuItem value={"READ"}>Read Only</MenuItem>
                <MenuItem value={"WRITE"}>Write Only</MenuItem>
                <MenuItem value={"NONE"}>None</MenuItem>
                <MenuItem disabled={data.user.homeFolderId === folderId}
                          value={"INHERITED"}>Inherited</MenuItem>
            </StyledSelect>];
    }

    return EditRow;
}

// noinspection FunctionNamingConventionJS
function UserFolderPermissionsTable(props) {

    const [showSavedMessage, setShowSavedMessage] = useState(false);
    const [userFolderPermissions, setUserFolderPermissions] = useState(props.userFolderPermissions);


    useEffect(() => {
        setUserFolderPermissions(props.userFolderPermissions);
    }, [props.userFolderPermissions]);

    const updatePermission = async function updateUserFolderPermission({itemToEdit: data}) {
        let response = await props.updatePermission(props.folderId ?? data.folderId, data.userId, data.newPerms ?? data.permission);
        let tempPermissions = _.map(userFolderPermissions, function updatePermission(permission) {
            if (permission.userId === data.userId) {
                return response.data;
            }
            return permission;
        });
        setUserFolderPermissions(tempPermissions);
        setShowSavedMessage(true);
    };

    return (
        <>
            <PageSection title='User Permissions' titleVariant='body1'
                         subtitle='This is the action users can take on this folder when connecting to the SFTP.'
                         subtitleVariant='body2' mt={3}
                         action={<Grid container alignItems='center'>
                             <SuccessMessageComponent show={showSavedMessage} setShow={setShowSavedMessage} mr={2}/>
                         </Grid>}/>
            <InlineEditTable
                data={userFolderPermissions}
                update={updatePermission}
                displayRow={displayRowWithInheritance(props.folderId)}
                header={['User', 'Permissions']}
                editRow={editRowWithInheritance(props.folderId)}
                columnWidths={[40, 40, 20]}
            />
        </>
    );
}

const UserFolderPermission = PropTypes.shape({
    folderId: PropTypes.any,
    userId: PropTypes.any,
    username: PropTypes.string,
    permission: PropTypes.string,
});

UserFolderPermissionsTable.propTypes = {
    folderId: PropTypes.any,
    userFolderPermissions: PropTypes.arrayOf(UserFolderPermission),
    updatePermission: PropTypes.func,
};

UserFolderPermissionsTable.defaultProps = {
    userFolderPermissions: [],
};

export default UserFolderPermissionsTable;
