import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Grid, useTheme } from '@mui/material';
import HelpSystemContext from '../../../context/HelpSystemContext';
import FormControlInput from '../../common/FormControlInput';
import CircularProgressButton from '../../common/CircularProgressButton';
import { IdentityProviderConfigInfo } from "./IdentityProviderConfigInfo";

const StyledButton = styled(Button)`
  margin-top: ${props => props.theme.spacing(2)};
`;

// noinspection FunctionNamingConventionJS
function IdentityProviderForm(props) {

    const theme = useTheme();
    const [loadingSubmit, setLoadingSubmit] = useState(false);

    useEffect(() => {
        setLoadingSubmit(false);
    }, [props.errors]);

    const handleSubmit = async function handleFolderSubmit(event) {
        setLoadingSubmit(true);
        await props.handleSubmit(event);
    };

    return (<HelpSystemContext.Consumer>
        {open =>
            <form onSubmit={handleSubmit} noValidate>
                <Grid container justifyContent='center'>
                    <Grid container item md={open ? 12 : 8}>
                        <FormControlInput value={props.name} width='100'
                                          label='Identity Provider Display Name'
                                          name='name' placeholder='Enter unique name' required onChange={props.setName}
                                          helperText='Name used to identify this identity provider.'
                                          errorMessage={props.errors.name}
                        />
                    </Grid>
                    <Grid container item md={open ? 12 : 8}>
                        <FormControlInput value={props.notes} width='100' label='Notes'
                                          name='notes' placeholder='Enter notes' onChange={props.setNotes}
                                          inputProps={{maxLength: 1000}} showCount
                                          helperText='Notes about this identity provider.'
                                          errorMessage={props.errors.notes}
                        />
                    </Grid>
                    <Grid container item md={open ? 12 : 8}>
                        <FormControlInput value={props.issuerUri} width='100' label='Issuer URI'
                                          name='issuerUri' placeholder='Enter issuer URI'
                                          onChange={props.setIssuerUri}
                                          inputProps={{maxLength: 1000}} showCount required
                                          helperText='Enter issuer URI.'
                                          errorMessage={props.errors.issuerUri}
                        />
                    </Grid>
                    <Grid container item md={open ? 12 : 8}>
                        <FormControlInput value={props.clientId} width='100' label='Client ID'
                                          name='clientId' placeholder='Client ID'
                                          onChange={props.setClientId} required
                                          helperText='Enter the client ID from the IdP.'
                                          errorMessage={props.errors.clientId}
                        />
                    </Grid>
                    <Grid container item md={open ? 12 : 8}>
                        <FormControlInput value={props.clientSecret} width='100' label='Client Secret'
                                          name='clientSecret' placeholder='Client Secret'
                                          onChange={props.setClientSecret} required
                                          helperText='Enter the client secret from the IdP.'
                                          errorMessage={props.errors.clientSecret}
                        />
                    </Grid>
                    <Grid container item md={open ? 12 : 8}>
                        <FormControlInput value={props.nameAttribute} width='100' label='Name Attribute'
                                          name='nameAttribute' placeholder='Name attribute'
                                          onChange={props.setNameAttribute} required
                                          helperText={'Set the attribute name used to access the user\'s name from the user info response.'}
                                          errorMessage={props.errors.nameAttribute}
                        />
                    </Grid>
                    <Grid container item md={open ? 12 : 8}>
                        <FormControlInput value={props.registrationId} width='100' label='Registration Id'
                                          name='registrationId' placeholder='Registration id'
                                          onChange={props.setRegistrationId} required
                                          helperText={'Set registration id.'}
                                          errorMessage={props.errors.registrationId}
                        />
                    </Grid>

                    <Grid item md={open ? 12 : 8} xs={12}>
                        <FormControlInput value={props.scopes}  label='Scopes'
                                          name='scopes' placeholder='openid'
                                          onChange={props.setScopes}
                                          helperText={'Set a list of extra OpenID Connect scopes separated by comma. The \'openid\' scope is always included.'}
                                          errorMessage={props.errors.scopes}
                        />
                    </Grid>

                    <Grid container item justifyContent='flex-end' md={open ? 12 : 8}>
                        <CircularProgressButton theme={theme} type='submit' size='small' mr={1}
                                                label='Save' mt={2} inProgress={loadingSubmit}/>
                        <StyledButton onClick={props.handleCancel} disableElevation size='small' type='reset'
                                      theme={theme}>Cancel</StyledButton>
                    </Grid>

                    <Grid item md={open ? 12 : 8} xs={12} style={{ marginTop: '16px' }}>
                        <IdentityProviderConfigInfo registrationId={props.registrationId}/>

                    </Grid>
                </Grid>
            </form>
        }
    </HelpSystemContext.Consumer>);
}

IdentityProviderForm.propTypes = {
    name: PropTypes.string,
    setName: PropTypes.func,
    notes: PropTypes.string,
    setNotes: PropTypes.func,
    clientId: PropTypes.string,
    setClientId: PropTypes.func,
    clientSecret: PropTypes.string,
    setClientSecret: PropTypes.func,
    handleSubmit: PropTypes.func,
    handleCancel: PropTypes.func,
    errors: PropTypes.object,
};

IdentityProviderForm.defaultProps = {};

export default IdentityProviderForm;
