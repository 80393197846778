import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import toast from 'toasted-notes';
import { useHistory } from 'react-router-dom';
import { Grid, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import routes from '../../../util/routes';
import ProgressFormCard from '../../common/ProgressFormCard';
import CustomAlert from '../../common/CustomAlert';
import CustomCardHeader from '../../common/CustomCardHeader';
import { StyledBreadcrumbIcon, StyledBreadcrumbLink, StyledBreadcrumbs } from '../../common/styled';
import IdentityProviderEditForm from './IdentityProviderEditForm';
import HelpSystemContext from '../../../context/HelpSystemContext';
import { isEmptyObject } from '../../../util/helpers';
import CircularProgressButton from '../../common/CircularProgressButton';
import SuccessMessageComponent from '../../common/SuccessMessageComponent';
import { red } from '@mui/material/colors';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
    },
}));

// noinspection FunctionNamingConventionJS
function IdentityProviderUpdateComponent(props) {

    const classes = useStyles();
    const history = useHistory();
    const theme = useTheme();

    const [identityProvider, setIdentityProvider] = useState(props.identityProvider);
    const [showDisableEnableMessage, setShowDisableEnableMessage] = useState(false);

    // Update the state from updated props.
    useEffect(() => {
        setIdentityProvider(props.identityProvider);
    }, [props.identityProvider]);

    const onSubmit = async function updateIdentityProvider(event, data) {
        event.preventDefault();
        await props.updateIdentityProvider(data);
    }

    const disableIdp = async function disableIdp(event) {
        try {
            await onSubmit(event, { enabled: false });
            setIdentityProvider({...identityProvider, enabled: false});
            setShowDisableEnableMessage(true);
        } catch (error) {
            console.error(error);
            toast.notify(
                ({onClose}) => <CustomAlert type='error' message='Could not disable this identity provider!' onClose={onClose} />);
        }
    }

    const enableIdp = async function disableIdp(event) {
        try {
            await onSubmit(event, { enabled: true });
            setIdentityProvider({...identityProvider, enabled: true});
            setShowDisableEnableMessage(true);
        } catch (error) {
            console.error(error);
            toast.notify(
                ({onClose}) => <CustomAlert type='error' message='Could not enable this identity provider!' onClose={onClose} />);
        }
    }

    return (
        <HelpSystemContext.Consumer>
            {open =>
                <div className={classes.root}>
                    <StyledBreadcrumbs aria-label='breadcrumb' theme={theme}>
                        <StyledBreadcrumbLink color='inherit' onClick={() => history.push(routes.settings.path)}>
                            <StyledBreadcrumbIcon theme={theme}/>
                            Back to settings
                        </StyledBreadcrumbLink>
                    </StyledBreadcrumbs>
                    <ProgressFormCard
                        header={
                            <Grid container alignContent='center' justifyContent='center'>
                                <Grid item md={open ? 12 : 8} style={{width: '100%'}}>
                                    <CustomCardHeader
                                        title={routes.updateIdentityProvider.pageTitle}
                                        titleAlign='center'
                                        action={
                                            <Grid container justifyContent='center'>
                                                <SuccessMessageComponent show={showDisableEnableMessage}
                                                                         setShow={setShowDisableEnableMessage} mr={2} />
                                                {!isEmptyObject(identityProvider) && !identityProvider.enabled &&
                                                    <CircularProgressButton mt={0} size='small' label='Enable IdP'
                                                                            style={{backgroundColor: red['800']}}
                                                                            buttonTextTransform='none'
                                                                            onClick={enableIdp}/>
                                                }
                                                {!isEmptyObject(identityProvider) && identityProvider.enabled &&
                                                    <CircularProgressButton mt={0} size='small'
                                                                            label='Disable IdP'
                                                                            buttonTextTransform='none'
                                                                            onClick={disableIdp} />
                                                }
                                            </Grid>
                                        }
                                    />
                                </Grid>
                            </Grid>
                        }
                        content={
                            <IdentityProviderEditForm handleSubmit={onSubmit}
                                                      name={identityProvider.name}
                                                      notes={identityProvider.notes}
                                                      issuerUri={identityProvider.issuerUri}
                                                      clientId={identityProvider.clientId}
                                                      clientSecret={identityProvider.clientSecret}
                                                      nameAttribute={identityProvider.nameAttribute}
                                                      registrationId={identityProvider.registrationId}
                                                      scopes={identityProvider.scopes}
                            />
                        }
                    />

                </div>
            }
        </HelpSystemContext.Consumer>
    )
}

IdentityProviderUpdateComponent.propTypes = {
    identityProvider: PropTypes.object,
};

IdentityProviderUpdateComponent.defaultProps = {};

export default IdentityProviderUpdateComponent;
