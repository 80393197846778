import React, { useEffect, useState } from 'react';
import { Card, CardContent, Grid, useTheme } from "@mui/material";
import dayjs from "dayjs";
import PropTypes from 'prop-types';
import LogComponent from "../../component/diagnostics/LogComponent";
import CustomCardHeader from "../../component/common/CustomCardHeader";
import Date from "../../component/common/Date";
import { isEmpty } from "../../util/helpers";

function LogContainer(props) {
  const theme = useTheme();
  const startDate = dayjs().subtract(1, 'hour');
  const [changeDateValue, setChangeDateValue] = useState(null);
  const [selectedDate, setSelectedDate] = useState(startDate.unix() * 1000);
  const [nextToken, setNextToken] = useState(null);
  const [localLogs, setLocalLogs] = useState([]);
  const [loadingMoreDisabled, setLoadingMoreDisabled] = useState(false);


  const changeDate = (data) => {
    if (!isEmpty(data)) {
      let newDate = data.unix() * 1000;
      setChangeDateValue(newDate);
    }
  };

  const {isLoading, logs, logsFetchedForStartDate, nextForwardToken, error} = props.useLogsEffect({
    params: {startDate: selectedDate, nextToken}
  });

  useEffect(() => {
    if(changeDateValue && changeDateValue !== selectedDate && !isLoading) {
      //This effect stops changes from happening until loading is done
      setSelectedDate(changeDateValue);
      setLocalLogs([]);
      setNextToken(null);
    }
  }, [changeDateValue, isLoading, selectedDate]);

  const loadMore = () => {
    if (nextToken !== nextForwardToken && nextForwardToken !== null) {
      setNextToken(nextForwardToken);
    }
  };

  useEffect(() => {
    if(!isLoading) {
      if (logsFetchedForStartDate !== selectedDate) {
        setLocalLogs([]);
      } else if (logs) {
        setLocalLogs(prevState => [].concat(prevState, logs));
      }
    }
  }, [logs, isLoading, logsFetchedForStartDate, selectedDate]);

  useEffect(() => {
    setLoadingMoreDisabled(nextForwardToken === null);
  }, [nextForwardToken])

  return (
    <Card variant='outlined'>
      <CustomCardHeader title={props.title} titleVariant={'h6'} style={{paddingBottom: '0px'}}/>
      <CardContent>
        <Grid container direction='row' alignItems='flex-start'>
          <Grid item style={{marginRight: `${theme.spacing(2)}px`}}>
            <Date name='startDate' label='Start Date' onChange={changeDate} value={startDate} />
          </Grid>
        </Grid>
        <Grid container>
          <LogComponent
            logs={localLogs}
            error={error}
            isLoading={isLoading}
            loadMore={loadMore}
            loadMoreDisabled={loadingMoreDisabled}
          />
        </Grid>
      </CardContent>
    </Card>
  )
}

LogContainer.propTypes = {
  title: PropTypes.string,
  useLogsEffect: PropTypes.func
};

LogContainer.defaultProps = {};

export default LogContainer;