import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import toast from 'toasted-notes';
import axios from 'axios';
import CircularProgressButton from '../common/CircularProgressButton';
import FormControlInput from '../common/FormControlInput';
import PasswordField from '../common/PasswordField';
import CustomAlert from '../common/CustomAlert';
import httpStatus from '../../util/http_status';
import api_routes from '../../util/api_routes';
import { isEmpty } from '../../util/helpers';
import routes from '../../util/routes';

const StyledLogo = styled.img`
  width: 100%;
`;

const StyledFormLogo = styled.div`
  width: 75%;
`;

const StyledFormContent = styled.div`
  display: flex;
  width: 100%;
  color: rgb(148, 146, 146);
  margin: 24px 0px 8px;
  &:before {
    content: "";
    flex: 1 1 0%;
    border-bottom: 1px solid rgb(216, 216, 216);
    margin: 0px 22px 8px 0px;
   }
  &:after {
    content: "";
    flex: 1 1 0%;
    border-bottom: 1px solid rgb(216, 216, 216);
    margin: 0px 0px 8px 22px;
  }
`;

const StyledFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 360px;
`;

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

// noinspection FunctionNamingConventionJS
export default function Login(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [loginForm, setLoginForm] = useState({
    username: '',
    password: '',
  });

  const [idps, setIdps] = useState({});
  const [idpGridSize, setIdpGridSize] = useState(4);

  const handleChange = function updateLoginFormFields(event) {
    setLoginForm({...loginForm, [event.target.name]: event.target.value})
  };

  const handleSignIn = async function signInToSFTPGatewayWebApp(event) {
    event.preventDefault();
    setIsLoading(true);
    try {
      await props.login(loginForm.username.trim(), loginForm.password);
    } catch (error) {
      if (!isEmpty(error.response)
          && (error.response.status === httpStatus.badRequest || error.response.status === httpStatus.unAuthorized)) {
        toast.notify(({onClose}) => <CustomAlert type='error'
                                                 message={error.response.data.error_description} onClose={onClose}/>);
      } else {
        toast.notify(({onClose}) =>
            <CustomAlert type='error' message='Something went wrong! Check that the server is running.' onClose={onClose}/>)
      }
      setIsLoading(false);
    }
  };

  function sortObj(obj) {
    return Object.keys(obj).sort().reduce(function (result, key) {
      result[key] = obj[key];
      return result;
    }, {});
  }

  useEffect(() => {
    async function fetchData(){
      try {
        let response = await axios.get(api_routes.configuredIdps.endpoint);
        setIdps(response.data);
        if (Object.entries(response.data).length === 1) {
          setIdpGridSize(12);
        } else if (Object.entries(response.data).length % 2 === 0) {
          setIdpGridSize(6);
        } else {
          setIdpGridSize(4);
        }
      } catch(e) {
        console.log('E', e);
      }
    }
    fetchData();
  }, []);

  return (
      <Grid container justifyContent='center' alignItems='center'
            className='login-form'>
        <StyledFormWrapper className='form-wrapper'>
          <StyledFormLogo className='form-logo'>
            <StyledLogo src='/images/sftpgateway-logo-final.png' alt='SFTPgateway logo'/>
            <StyledLogo src='/images/sftpgateway-logo-final-text.png' alt='SFTPgateway logo text'/>
          </StyledFormLogo>
          <StyledFormContent className='form-content'>
            Sign In
          </StyledFormContent>
          <div>
            <form onSubmit={handleSignIn}>
              <FormControlInput width='100' required name='username' value={loginForm.username}
                                label='Username' onChange={handleChange}/>
              <PasswordField width={'100'} required password={loginForm.password} label='Password'
                             handleChange={handleChange}/>
              <CircularProgressButton label='Sign In' variant='contained' size='medium' mt={2}
                                      type='submit' inProgress={isLoading}/>
            </form>
          </div>
          {Object.entries(idps).length > 0 &&
              <>
                <StyledFormContent className='form-content'>
                  or Sign In with
                </StyledFormContent>
                <Grid item container direction='row' justifyContent='center' alignItems='center' spacing={1} sx={{mt: 1}}>
                  {Object.entries(sortObj(idps)).map(([registrationId, displayName]) =>
                      <Grid item xs={idpGridSize} key={registrationId}>
                        <CircularProgressButton fullWidth label={displayName}
                                variant="outlined" buttonTextTransform={'none'}
                                href={`${BASE_URL}/oauth2/authorization/${registrationId}?state=${window.btoa(encodeURIComponent(window.location.origin + routes.token.path))}`} />
                      </Grid>
                  )}
                </Grid>
              </>
          }


        </StyledFormWrapper>
      </Grid>
  )
}

Login.prototype = {
  login: PropTypes.func,
  oidcLogin: PropTypes.func,
};
