import React, {useEffect, useState} from 'react';
import axios from 'axios';
import api_routes from '../util/api_routes';

const initParams = {
  identityProviderId: 0,
};

const initIdentityProvider = {};

// noinspection FunctionNamingConventionJS
function useIdentityProvider({ params = initParams }) {

    const { identityProviderId } = params;
    const [identityProvider, setIdentityProvider] = useState(initIdentityProvider);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(function loadIdentityProvider() {
      const fetchIdentityProviderData = async function fetchIdentityProviderData() {
          try {
            const result = await axios.get(`${api_routes.identityProvider.endpoint}/${identityProviderId}`);
            setIdentityProvider(result.data);
            setIsLoading(false);
          } catch (error) {

          }
      };

      setIsLoading(true);
      // noinspection JSIgnoredPromiseFromCall
      fetchIdentityProviderData();
    }, []);

    return {isLoading, identityProvider};
}

export default useIdentityProvider;
