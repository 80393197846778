import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, ListItemIcon, Typography } from '@mui/material';
import UserFolderPermissionsTable from './UserFolderPermissionsTable';
import { isEmpty } from '../../util/helpers';
import InlineEditTextField from '../common/InlineEditTextField';
import InlineEditSelectField from '../common/InlineEditSelectField';
import HelpSystemContext from '../../context/HelpSystemContext';
import { Icon } from "@iconify/react";
import awsIcon from "@iconify-icons/mdi/aws";
import azureIcon from "@iconify-icons/mdi/microsoft-azure";
import googleIcon from "@iconify-icons/mdi/google-cloud";
import styled from 'styled-components';

const StyledTableIcon = styled(ListItemIcon)`
  margin-left: 0.5rem;
  min-width: 32px;
  vertical-align: middle;
`;

// noinspection FunctionNamingConventionJS
function FolderEditForm(props) {
    const providerIcon = (connection) => {
        if (connection) {
            switch (connection.cloudProvider) {
                case "aws":
                    return awsIcon;
                case "azure":
                    return azureIcon;
                case "gcp":
                    return googleIcon
                default:
                    return null;
            }
        }
    }

    const getOptions = function getOptions() {
        let tempOptions = [];
        if (props.id !== 0) {
            tempOptions = [{key: '-1', value: '-1', displayValue: 'Inherited'}];
        }
        return tempOptions.concat(props.cloudConnections.map(cloudConnection =>
            ({key: cloudConnection.id, value: cloudConnection.id, displayValue: cloudConnection.name})));
    };

    // noinspection ConditionalExpressionJS
    return (
        <HelpSystemContext.Consumer>
            {open =>
                <form>
                    <Grid container justifyContent='center'>
                        <Grid container item md={open ? 12 : 8}>
                            <InlineEditTextField name='name' label='Folder Name' labelVariant='body1' value={props.name}
                                                 updateMessage='Saved' required
                                                 save={(event, data) => props.handleSubmit(event, data)}/>
                        </Grid>
                        <Grid container item md={open ? 12 : 8}>
                            <UserFolderPermissionsTable
                                folderId={props.id}
                                userFolderPermissions={props.folderPermissions}
                                updatePermission={props.updatePermission}
                                loadingFolderPermissions={props.loadingFolderPermissions}/>
                        </Grid>
                        <Grid container item md={open ? 12 : 8}>
                            <Grid item>
                                <Box mt={3}>
                                    <InlineEditSelectField name='cloudConnectionId' label='Cloud Connection' width='100'
                                                           helperText='This is the cloud connection that will be used by this folder.'
                                                           labelVariant='body1' updateMessage='Saved'
                                                           value={isEmpty(props.cloudConnectionId?.toString()) ? '-1'
                                                               : props.cloudConnectionId?.toString()}
                                                           save={(event, data) => props.handleSubmit(event, data)}
                                                           options={getOptions()}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container item md={open ? 12 : 8}>
                            <Grid item>
                                <Box mt={3}>
                                    <Typography gutterBottom variant='body1' component='div'>
                                        Resolved Cloud Path
                                    </Typography>
                                    <Typography variant='body2'>
                                        {!isEmpty(props.cloudConnectionId) && !props.loadingCloudConnections &&
                                            <StyledTableIcon>
                                                <Icon width={22}
                                                      icon={
                                                          providerIcon(
                                                              props.cloudConnections.find(
                                                                  connection => connection.id
                                                                      === props.cloudConnectionId)
                                                          )}/>
                                            </StyledTableIcon>
                                        }
                                        {props.cloudHttpPath}

                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            }
        </HelpSystemContext.Consumer>
    );
}

const CloudConnection = PropTypes.shape({
    id: PropTypes.any,
    name: PropTypes.string,
});

FolderEditForm.propTypes = {
    handleSubmit: PropTypes.func,
    handleCancel: PropTypes.func,
    name: PropTypes.string,
    cloudConnectionId: PropTypes.any,
    cloudHttpPath: PropTypes.string,
    setName: PropTypes.func,
    setCloudConnectionId: PropTypes.func,
    cloudConnections: PropTypes.arrayOf(CloudConnection),
    folderPermissions: PropTypes.array,
    deleteFolder: PropTypes.func,
    updatePermission: PropTypes.func,
    loadingFolderPermissions: PropTypes.bool,
    id: PropTypes.any,
};

FolderEditForm.defaultProps = {
    cloudConnections: [],
    cloudConnectionId: '',
};

export default FolderEditForm;
